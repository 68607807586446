import Square from "./Square";

const lines = [
  [0, 1, 2],
  [3, 4, 5],
  [6, 7, 8],
  [0, 3, 6],
  [1, 4, 7],
  [2, 5, 8],
  [0, 4, 8],
  [2, 4, 6]
];

export default function Board({ xIsNext, squares, onPlay }) {
  function calculateWinner(squares) {
    for(let i = 0; i < lines.length; i++){
      const [a, b, c] = lines[i];
      if(squares[a] && squares[a] === squares[b] && squares[a] === squares[c]){
        return i;
      }
    }
    return null;
  }

  function handleClick(i) {
    if(squares[i] || calculateWinner(squares)){
      return;
    }
    const nextSquares = squares.slice();
    if(xIsNext){
      nextSquares[i] = "X";
    } else {
      nextSquares[i] = "O";
    }
    onPlay(nextSquares);
  }

  const winner = calculateWinner(squares);
  let status;
  if(winner){
    status = "Winner: " + squares[lines[winner][0]];
  } else if(squares.includes(null)){
    status = "Next player: " + (xIsNext ? "X" : "O");
  } else {
    status = "Game Draw";
  }

  return (
    <>
      <div className="status">
        {status}
      </div>
      {Array(3).fill(null).map((key, i) => {
        return (
          <div key={i} className="board-row">
            {Array(3).fill(null).map((key2, j) => {
              return <Square key={3*i+j} value={squares[3*i+j]} onSquareClick={() => handleClick(3*i+j)} winningSquare={Boolean(winner !== null && ((lines[winner][0] === 3*i+j) || (lines[winner][1] === 3*i+j) || (lines[winner][2] === 3*i+j)))}/>
            })}
          </div>
        );
      })
      }
    </>
  );
}