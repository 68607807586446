import { useState } from 'react';
import Board from './components/Boards';

export default function Game() {
  const [history, setHistory] = useState([Array(9).fill(null)]);
  const [currentMove, setCurrentMove] = useState(0);
  const [ascending, setAscending] = useState(true);
  const xIsNext = currentMove % 2 === 0;
  const currentSquares = history[currentMove];

  function handlePlay(nextSquares) {
    const nextHistory = [...history.slice(0, currentMove + 1), nextSquares];
    setHistory(nextHistory);
    setCurrentMove(nextHistory.length - 1);
  }

  function jumpTo(nextMove) {
    setCurrentMove(nextMove);
  }

  function printMoves(hist, asc) {
    const moves = [];
    let pos = [{
      x: null, 
      y: null
    }];
    for(let i = 0; i < hist.length - 1; i++){
      for(let j = 0; j < 9; j++){
        if(hist[i][j] !== hist[i+1][j]){
          pos.x = Math.floor(j/3);
          pos.y = Math.floor(j%3);
          console.log(Math.floor(j/3),Math.floor(j%3) )
        }
      }
      let desc = i === 0 ? "Go to game start" : "Go to move #" + i + " at (" + pos.x + ", " + pos.y + ")";
      moves.push(
        <li key={i}>
          <button onClick={() => jumpTo(i)}>{desc}</button>
        </li>
      );
    }
    moves.push(
      <li key={hist.length - 1}>
        {hist.length > 1 ?
          <p>You're at moves {hist.length - 1} at ({pos.x}, {pos.y})</p> :
          <p>You're at game start</p>
        }
      </li>
    );
    return asc ? moves : moves.reverse();
  }

  return (
    <div className="game">
      <div className="game-board">
        <Board xIsNext={xIsNext} squares={currentSquares} onPlay={handlePlay} />
      </div>
      <div className="game-info">
        <p>Sort moves by: </p>
        <button onClick={() => { setAscending(!ascending) }}>
          {ascending ? "Descending" : "Ascending"}
        </button>
        <ol>{printMoves(history, ascending)}</ol>
      </div>
    </div>
  );
}